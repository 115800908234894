import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const Glossary = () => (
  <Layout>
    <SEO
      title="Natural Hair Care Terms"
      description="Finally, the list you've been looking for. Everything you need to know about natural afro hair care. Use this list to help you grow long textured 4c curls"
    />
    <h1>Natural Hair Care Terms</h1>
    <h2 style={{color:"Orchid"}}>What do all these natural hair terms mean anyway?</h2>
    <p>We are covering the jargon used in the natural hair community. A lot of websites and groups use all sorts words and acronyms that if you are a beginner, it can be difficult to catch on. Early on I found it difficult and had to google search each new word I came across. I always thought a natural hair glossary would be perfect. This is something we will update as new techniques surface and we hope this is a resource you find useful and can share with your friends & family.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/cswDUWU.jpg"
        alt="natural afro hair glossary jargon header"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@skmuse_?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Suad Kamardeen</a></p>
    </div>

    <h3>Big Chop</h3>
    <p>Cutting off the hair either completely or really short with the aim of growing back natural untreated and healthy hair. It is a drastic method but a simple one and can feel liberating. It is a fresh start and the day can be defined as the start of a journey.</p>

    <h3>Co-wash</h3>
    <p>Washing the hair with conditioner only. Shampoo often contains chemicals that can damage the hair and strip it of its nutrients. Many people have routine when they wash their hair at set intervals for example once every week or two then co-wash more frequently between. This is a good compromise for those trying to keep the hair clean and retaining the nutrients necessary for healthy natural hair.</p>

    <h3>Detangling</h3>
    <p>Method of reducing tangles in the hair to make it easier to manage. Detangler coats the hair and makes it smoother which allows a comb or brush to pass through more easily. Combing dry or tangled hair can cause lots of breakage, when you are trying to grow really curly hair (4c hair for example) it is essential to not force combing, it doesn’t have to be painful.</p>

    <h3>Deep condition</h3>
    <p>Treatment that moisturises the hair. Deep conditioner is applied the hair for a long period of time usually wrapped up to prevent heat loss or with heat applied. Deep conditioner can be bought ready-made and some people make them themselves.</p>

    <h3>Hair length (Acronyms in length order)</h3>
    <ul>
        <li>EL – ear length</li>
        <li>NP – nape length</li>
        <li>SL – shoulder length</li>
        <li>APL – arm pit length</li>
        <li>MBL – mid back length</li>
        <li>WL – waist length</li>
    </ul>

    <h3>Hair type</h3>
    <p>One example of a classification system that defines hair types was created by Andre Walker, it is a system that uses a number for the first character and a letter ranging from a-c for the second. Natural afro hair usually falls somewhere between 3a to 4c. 3a being the looser end of the scale and 4c being the most curly and tight end of the scale.</p>

    <h3>Pre-poo</h3>
    <p>Applying product to the hair before washing. The aim is to protect the hair and pre-empt the inadvertent loss of nutrients when trying to clean the hair. Many people use homemade mixtures for this that contain some sort of protein mixed with natural oil.</p>

    <h3>Protective hairstyle</h3>
    <p>Styles that protect the hair and help reduce breaking. Protective styles usually help the hair to grow. They protect mainly against the sun, heat, cold and friction that can split ends.</p>

    <h3>Sealing</h3>
    <p>Locking moisture into the hair. Some products are specifically made to seal the hair. Due to their nature, sealers leave the hair looking glossy. It is important to use sealer properly by adding moisture beforehand because glossy looking hair does not necessarily mean it is healthy and moisturised.</p>

    <h3>Slip</h3>
    <p>This describes how slippery a hair product is. It usually applies to detanglers and conditioners. If your hair is really curly and particularly difficult to manage, you’ll want a high slip to make your hair easier to comb.</p>

    <h3>Transitioning</h3>
    <p>When you are growing out new untreated hair and the old treated hair has not been trimmed. This can be a difficult and frustrating time because the hair is visibly different at the roots compared to the ends. This is why some of us go for a big chop (see above).</p>

    <h3>Twist out</h3>
    <p>A method of styling the hair. It involves styling the hair, letting some time pass to allow the hair to hold the structure of the style then undoing the style. In literal terms, the twist out applies to twist specifically but it can refer to plaits. The style is usually applied after some leave in conditioner has been used and then kept in overnight. The moisture is locked in meaning in the morning all you have to do is undo the style and manipulate the hair to hide any parting you do not want to see.</p>

    <h3>Virgin Hair</h3>
    <p>Hair has never seen any kind of chemical or heat treatment. The goal of many in the natural hair community is to have a head full of virgin hair. It can be difficult to achieve but that’s why we are here, to help you during the journey.</p>

    <h3>Wet and go</h3>
    <p>Similar to wash and go but you use water with leave-in conditioner. This helps reduce any frizz that you might have and can help define curls. The amount you spray and the ratio of water to conditioner depends on your hair type. You have to experiment and find what works for you.</p>

    <h3>Wash and go</h3>
    <p>Washing the hair then adding your styling product and going. The hair will air dry as you get on with your day. A benefit is that it is that the process is much quicker than your usual routine. You probably wouldn’t be able to do this in winter because your hair would remain wet for much longer due to the cold air.</p>

    <h2 style={{color:"Orchid"}}>Conclusion</h2>
    <p>Thank you for using our glossary. Feel free to share this and offer suggestions. Again, we will add to the list when we come across new words and terms. Our aim is to demystify the information surrounding natural hair when at times it can feel like you need a doctorate in hair techniques to understand what is going on.</p>
    

    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>                  
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default Glossary
